import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { hero, heroText, logo, mobileHero, mobileHeroText } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="95vh"
          w="100%"
          image={`url(${mobileHero}) center / cover no-repeat`}
          column
          center
        >
          <CFView textCenter column center w="100%">
            <CFImage
              w="95%"
              maxWidth="350px"
              src={heroText}
              alt="Bukchon Korean Cuisine hero text"
              zIndex={98}
            />
            <CFView mt="25px" pulsate>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          w="100%"
          h="100vh"
          maxHeight="650px"
          image={`url(${hero}) center / cover no-repeat`}
          style={{ backgroundAttachment: 'fixed' }}
          column
          center
        >
          <CFView column center textCenter bg="rgba(0,0,0,.75)" p="35px">
            <CFImage
              w="80%"
              maxWidth="520px"
              src={heroText}
              alt="Bukchon Korean Cuisine hero text"
              mb="30px"
              zIndex={98}
            />
            <CFView pulsate zIndex={98}>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
